export const CustomSortCaret = order => {
  if (!order)
    return (
      <span className="custom-sort-cart">
        <div style={{ display: 'inline-block' }}>
          ▲
          <br />▼
        </div>
      </span>
    );
  else if (order === 'asc') return <span>&nbsp;&nbsp;▲&nbsp;&nbsp;</span>;
  else if (order === 'desc') return <span>&nbsp;&nbsp;▼&nbsp;&nbsp;</span>;
  return null;
};
