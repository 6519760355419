import { useEffect, useState } from 'react';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Button from 'components/base/Button';
import { useNavigate, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner, Dropdown } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import FeatherIcon from 'feather-icons-react';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getInventory, getSubCategories, archiveItem } from 'api/api';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import Select from 'react-select';
import Swal from 'sweetalert2';
import makeAnimated from 'react-select/animated';
import DownloadButton from 'helpers/download-utils';

const Inventory = () => {
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [missingTotal, setMissingTotal] = useState(null);
  const [activeTotal, setActiveTotal] = useState(null);
  const [soldTotal, setSoldTotal] = useState(null);
  const [sortColumn, setSortColumn] = useState('asc');
  const [status, setStatus] = useState('all');
  const [allCount, setAllCount] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [columnName, setColumnName] = useState('id');
  const [downLoadData, setDownLoadData] = useState([]);
  const [clearButtonVisibility, setClearButtonVisibility] = useState(false);
  const [categoryName, setCategoryName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortString, setSortString] = useState('item.id:desc');

  useEffect(() => {
    loadData(currentPage, pageSize, status, sortString, null, null);
  }, []);

  useEffect(() => {
    loadDownLoadData(
      status,
      searchText,
      selectedCategory && selectedCategory.value
    );
  }, []);

  useEffect(() => {
    loadCategories(status);
  }, []);

  const loadData = (pageNr, pageSize, status, sortBy, q, category) => {
    setError([]);
    setLoading(true);
    setData([]);
    setActiveTotal(0);
    setMissingTotal(0);
    setSoldTotal(0);
    setAllCount(0);

    getInventory(pageNr, pageSize, status, sortBy, q, category)
      .then(response => {
        setTotal(response.data.data.total);
        console.log(response);
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.stock.id,
            name: item.name,
            item_id: item.id,
            sku: item.sku,
            description: item.description,
            serial_number: item.stock.serial_number,
            category: item.category.name,
            parent_category: item.category.parent_category.name,
            epc: item.stock && item.stock.epc.slice(-8),
            location: item.container.location.name,
            container: item.container.name,
            status: item.stock.status.name,
            image: item.image,
            stock_id: item.stock.id
          }));

          setData(flattenedData);

          setDate(
            moment(response.data.data.data[0].added_time).format(
              'MM DD YYYY, HH:MM'
            )
          );
          setName(
            `${response.data.data.data[0].added_user.first_name} ${response.data.data.data[0].added_user.last_name}`
          );
        }

        if (
          response.data.data.stats !== null &&
          response.data.data.stats !== undefined
        ) {
          setActiveTotal(response.data.data.stats.active);
          setMissingTotal(response.data.data.stats.missing);
          setSoldTotal(response.data.data.stats.sold);
          setAllCount(response.data.data.stats.all);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadDownLoadData = (status, sText, categoryId) => {
    setError([]);
    setDownLoadData([]);
    getInventory(1, 0, status, 'item.id:desc', sText, categoryId && categoryId)
      .then(response => {
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            serial_number: item.stock.serial_number,
            make: item.name,
            model: item.description,
            category: item.category.name,
            calibre: item.sku,
            epc: item.stock && item.stock.epc.slice(-8),
            // location: item.container.location.name,
            container: item.container.name,
            status: item.stock.status.name
          }));

          setDownLoadData(flattenedData);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const loadCategories = () => {
    setError([]);
    setLoading(true);

    getSubCategories(1, 0, `name:asc`)
      .then(response => {
        const data = response.data.data.data;
        setCategoryName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'image',
      formatter: (cellContent, row) => (
        <Link
          to={`/items/item-details/${row.item_id}`}
          className="rounded-2 border d-inline-block"
        >
          <img src={row.image} alt="" width={53} />
        </Link>
      ),
      headerStyle: () => ({ width: '80px' })
    },

    {
      dataField: 'serial_number',
      text: 'SERIAL NUMBER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'serial_number'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.serial_number}</>;
      }
    },

    {
      dataField: 'name',
      text: 'MAKE',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return (
          <>
            <Link
              to={`/items/item-details/${row.item_id}`}
              className="fw-semi-bold line-clamp-3"
            >
              {row.name}
            </Link>
          </>
        );
      }
    },
    {
      dataField: 'description',
      text: 'MODEL',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.description}</>;
      }
    },
    {
      dataField: 'category',
      text: 'CATEGORY',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'category'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.category}</>;
      }
    },

    {
      dataField: 'sku',
      text: 'CALIBRE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'sku' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.sku}</>;
      }
    },
    {
      dataField: 'epc',
      text: 'EPC',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'epc' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'container',
      text: 'CONTAINER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'container'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.container}</>;
      }
    },

    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (
          row.status.toLowerCase() === 'sold' ||
          row.status.toLowerCase() === 'in stock'
        ) {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    },
    {
      dataField: 'actions',
      formatter: (cell, row) => (
        <div className="table-action-button">
          {row.status.toLowerCase() === 'sold' && (
            <RevealDropdownTrigger>
              <RevealDropdown
                onSelect={eventKey => handleSelect(eventKey, row)}
              >
                <Dropdown.Item
                  className="text-info"
                  eventKey={`archive:${row.id}`}
                >
                  Archive
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>
          )}
        </div>
      )
    }
  ];

  const handleSelect = (eventKey, row) => {
    // Handle the selection here
    const parts = eventKey.split(':');
    if (parts[0] === 'archive') {
      confirmAlert(row);
    }
  };

  const confirmAlert = row => {
    Swal.fire({
      title: 'Are you sure?',
      html: `You want to archive the item <b>${row.name} </b>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, archive it!'
    }).then(result => {
      if (result.isConfirmed) {
        archive(row);
      }
    });
  };

  const archive = row => {
    setError([]);
    setLoading(true);
    archiveItem(row.item_id, row.stock_id)
      .then(response => {
        if (response.status === 200) {
          loadData(
            1,
            10,
            status,
            sortString,
            searchText,
            selectedCategory && selectedCategory.value
          );
        }
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const handleAllClick = () => {
    setStatus('all');
    setSortString('item.id:desc');
    setCurrentPage(1);
    loadData(
      1,
      10,
      'all',
      'item.id:desc',
      searchText,
      selectedCategory && selectedCategory.value
    );
    loadDownLoadData(
      'all',
      searchText,
      selectedCategory && selectedCategory.value
    );
  };
  const handleMissingClick = () => {
    setStatus('missing');
    setSortString('item.id:desc');
    setCurrentPage(1);
    loadData(
      1,
      10,
      'missing',
      'item.id:desc',
      searchText,
      selectedCategory && selectedCategory.value
    );
    loadDownLoadData(
      'missing',
      searchText,
      selectedCategory && selectedCategory.value
    );
  };
  const handleActiveClick = () => {
    setStatus('active');
    setSortString('item.id:desc');
    setCurrentPage(1);
    loadData(
      1,
      10,
      'active',
      'item.id:desc',
      searchText,
      selectedCategory && selectedCategory.value
    );
    loadDownLoadData(
      'active',
      searchText,
      selectedCategory && selectedCategory.value
    );
  };
  const handleSoldClick = () => {
    setStatus('sold');
    setSortString('item.id:desc');
    setCurrentPage(1);
    loadData(
      1,
      10,
      'sold',
      'item.id:desc',
      searchText,
      selectedCategory && selectedCategory.value
    );
    loadDownLoadData(
      'sold',
      searchText,
      selectedCategory && selectedCategory.value
    );
  };

  const tabItems = [
    {
      label: 'All Items',
      value: 'all',
      count: allCount,
      onClick: handleAllClick
    },
    {
      label: 'Missing',
      value: 'completed',
      count: missingTotal,
      onClick: handleMissingClick
    },
    {
      label: 'In Stock',
      value: 'refunded',
      count: activeTotal,
      onClick: handleActiveClick
    },
    {
      label: 'Sold',
      value: 'failed',
      count: soldTotal,
      onClick: handleSoldClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  const handleSearchInputChange = e => {
    setCurrentPage(1);
    setSearchText(e.target.value);
    setClearButtonVisibility(true);

    if (e.target.value.length >= 2) {
      setSortString('item.id:desc');
      loadData(
        1,
        10,
        status,
        'item.id:desc',
        e.target.value,
        selectedCategory && selectedCategory.value
      );
      loadDownLoadData(
        status,
        e.target.value,
        selectedCategory && selectedCategory.value
      );
    }
    if (e.target.value.length === 0) {
      setSortString('item.id:desc');
      loadData(1, 10, 'all', 'item.id:desc', null, null);
      setStatus('all');
      loadDownLoadData('all');
    }
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setSortString('item.id:desc');
    setSearchText('');
    setSelectedCategory(null);
    loadData(1, 10, 'all', 'item.id:desc', null, null);
    setStatus('all');
    loadDownLoadData('all');
    setClearButtonVisibility(false);
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    console.log(sortString);
    loadData(
      page,
      sizePerPage,
      status,
      sortString,
      searchText,
      selectedCategory && selectedCategory.value
    );
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`item.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'sku':
        setColumnName('sku');
        setSortString(`item.sku:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.sku:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'description':
        setColumnName('description');
        setSortString(`item.description:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.description:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'epc':
        setColumnName('epc');
        setSortString(`epc:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `epc:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'serial_number':
        setColumnName('serial_number');
        setSortString(`serial_number:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `serial_number:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'parent_category':
        setColumnName('parent_category');
        setSortString(`item.item_category.item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.item_category.item_category.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'category':
        setColumnName('category');
        setSortString(`item.item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.item_category.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'location':
        setColumnName('location');
        setSortString(`item.container.location.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.container.location.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'container':
        setColumnName('container');
        setSortString(`item.container.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `item.container.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(
          1,
          10,
          status,
          `status.name:${sortColumn}`,
          searchText,
          selectedCategory && selectedCategory.value
        );
        break;

      default:
        break;
    }
  };

  const handleChangeCategory = e => {
    setCurrentPage(1);
    setClearButtonVisibility(true);
    setSelectedCategory(e);
    loadData(
      1,
      10,
      status,
      `item.item_category.name:${sortColumn}`,
      searchText,
      e.value
    );
    loadDownLoadData(status, searchText, e.value);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      fontSize: '12px'
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '12px'
    })
  };

  return (
    <div>
      <div className="mb-9">
        <div>
          <h2 className="mb-2">Inventory</h2>
          <h5 className="text-700 fw-semi-bold">
            {`The latest inventory was conducted by ${name} on ${date}`}
          </h5>
        </div>

        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search by make, serial number , EPC or model"
                onChange={handleSearchInputChange}
                value={searchText}
              />
              <div style={{ width: '250px' }}>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    categoryName &&
                    categoryName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  placeholder={'Select category'}
                  value={selectedCategory}
                  styles={customStyles}
                  onChange={e => handleChangeCategory(e)}
                  required
                  name="location"
                  menuPlacement="auto"
                />
              </div>

              <div style={{ width: '130px' }}>
                <Button
                  variant="phoenix-secondary"
                  style={{
                    visibility:
                      clearButtonVisibility === true ? 'visible' : 'hidden'
                  }}
                  onClick={() => handleClearFilters()}
                >
                  Clear Filters
                </Button>
              </div>
              <div className="ms-xxl-auto">
                <DownloadButton
                  data={downLoadData}
                  info={`The latest inventory was conducted by ${name} on ${date}`}
                  allCount={allCount}
                  missingCount={missingTotal}
                  inStockCount={activeTotal}
                  soldCount={soldTotal}
                />
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Inventory;
