import InventoryInventoryChart from 'components/charts/e-charts/InventoryInventoryChart';
import { Card } from 'react-bootstrap';

interface Inventory {
  date: string;
  found_total: number;
  missing_total: number;
}

interface InventoryInventoryCardProps {
  inventory: Inventory[];
}

const InventoryInventoryCard = ({ inventory }: InventoryInventoryCardProps) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">Inventory</h5>
            <h6 className="text-700">{`Last ${
              inventory && inventory.length
            } days`}</h6>
          </div>
          <h4></h4>
        </div>
        <div className="pb-0 pt-4">
          <InventoryInventoryChart inventory={inventory} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default InventoryInventoryCard;
