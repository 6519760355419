import React, { useEffect, useState } from 'react';
import { Alert, Card, Row, Col, Spinner } from 'react-bootstrap';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { getUserProfile } from 'api/api';

function UserProfile() {
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setError([]);
    setLoading(true);

    getUserProfile()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Profile</h2>
            <h5 className="text-700 fw-semi-bold"></h5>
          </div>
        </div>
        <div>
          {error &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '200px' }}
          >
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <div>
            <Row className="g-3 mb-6">
              <Col xs={12} lg={8}>
                <Card className="h-100">
                  <Card.Body>
                    <div className="border-bottom border-dashed border-300 pb-4">
                      <Row className="align-items-center g-3 g-sm-5 text-center text-sm-start">
                        <Col xs={12} sm="auto">
                          <Avatar size={'5xl'} status={'online'} src={avatar} />
                        </Col>
                        <Col xs={12} sm="auto" className="flex-1">
                          <h3>
                            {data !== null &&
                              `${data.first_name} ${data.last_name}`}
                          </h3>
                          <p className="text-800">
                            {data !== null && `${data.user_group.name}`}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex flex-between-center pt-4">
                      <div>
                        <h6 className="mb-2 text-800"></h6>
                        <h4 className="fs-7 text-1000 mb-0"></h4>
                      </div>
                      <div className="text-end">
                        <h6 className="mb-2 text-800"></h6>
                        <h4 className="fs-7 text-1000 mb-0"></h4>
                      </div>
                      <div className="text-end">
                        <h6 className="mb-2 text-800"></h6>
                        <h4 className="fs-7 text-1000 mb-0"></h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
