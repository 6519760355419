import { Spinner } from 'react-bootstrap';

const LoadingOverlay = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(5px)',
        pointerEvents: 'none'
      }}
    >
      <div
        style={{
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Spinner />
      </div>
    </div>
  );
};

export default LoadingOverlay;
