import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import InventoryStats from 'components/stats/InventoryStats';
import InventoryTotalOrdersCard from 'components/cards/InventoryTotalOrdersCard';
import InventoryTotalInventoryCard from 'components/cards/InventoryTotalInventoryCard';
import InventoryInventoryCard from 'components/cards/InventoryInventoryCard';
import InventoryTopOrderItemsCard from 'components/cards/InventoryTopOrderItemsCard';
import InventoryLastInventoryCard from 'components/cards/InventoryLastInventoryCard';
import InventoryLatestOrdersTable from 'components/tables/InventoryLatestOrdersTable';
import { Alert, Spinner } from 'react-bootstrap';
import { getDashboard } from 'api/api';

const InventoryDashBoard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastOrdersData, setLastOrdersData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('inventory_token');
    if (token === null) navigate('/sign-in');
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setError([]);
    setLoading(true);
    getDashboard()
      .then(response => {
        setData(response.data.data);
        setLastOrdersData(response.data.data.last_orders.data);
      })
      .catch(err => {
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }

          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '200px' }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <div className="pb-5">
            <div>
              {error &&
                error.map((e, index) => (
                  <Alert variant="soft-danger" key={index}>
                    {e}
                  </Alert>
                ))}
            </div>

            <Row className="g-4">
              <Col xs={12} xxl={6}>
                <div className="mb-8">
                  <h2 className="mb-2">Inventory Management Dashboard</h2>
                  <h5 className="text-700 fw-semi-bold">
                    Here’s what’s going on at your business right now
                  </h5>
                </div>
                <InventoryStats
                  total_instock={data.length === 0 ? 0 : data.total_instock}
                  total_orders={data.length === 0 ? 0 : data.total_orders}
                  total_outofstock={
                    data.length === 0 ? 0 : data.total_outofstock
                  }
                />

                <InventoryTotalInventoryCard
                  inventory={
                    data &&
                    data.total_inventory &&
                    data.total_inventory.inventory
                  }
                  last_inventory_log={
                    data &&
                    data.total_inventory &&
                    data.total_inventory.last_inventory_log
                  }
                />
              </Col>
              <Col xs={12} xxl={6}>
                <Row className="g-3">
                  <Col xs={12} md={6}>
                    <InventoryTotalOrdersCard
                      orders={
                        data && data.orders_by_date && data.orders_by_date
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InventoryInventoryCard
                      inventory={
                        data && data.inventory_by_date && data.inventory_by_date
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InventoryTopOrderItemsCard
                      topOrderItems={
                        data && data.top_order_items && data.top_order_items
                      }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InventoryLastInventoryCard
                      lastInventoryLog={
                        data &&
                        data.last_inventory_log &&
                        data.last_inventory_log
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 border-y border-300">
            <InventoryLatestOrdersTable
              latestOrders={lastOrdersData && lastOrdersData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default InventoryDashBoard;
