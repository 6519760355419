import { useEffect, useState } from 'react';
//import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner } from 'react-bootstrap';
import FilterTab from 'components/common/FilterTab';
import useAdvanceTable from 'hooks/useAdvanceTable';
import moment from 'moment';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getUseLogs } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Users',
    url: '/users/users'
  },
  {
    label: 'Logs',
    active: true
  }
];

const UserLogs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('asc');
  const { id } = useParams();
  const [userName, setUserName] = useState('');
  const [columnName, setColumnName] = useState('id');
  const [sortString, setSortString] = useState('id:desc');

  useEffect(() => {
    loadData(id, currentPage, pageSize, 'id:desc');
  }, []);

  const loadData = (id, pageNr, pageSize, sortBy) => {
    setError([]);
    setLoading(true);

    getUseLogs(id, pageNr, pageSize, sortBy)
      .then(response => {
        setData(response.data.data.data);
        setTotal(response.data.data.total);
        if (response.data.data.data.length > 0) {
          const userData = response.data.data.data[0];
          setUserName(`${userData.user.first_name} ${userData.user.last_name}`);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'ip',
      text: 'TP ADDRESS',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'ip' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'log_time',
      text: 'LOG TIME',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'log_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{moment(row.log_time).format('MM-DD-YYYY HH:mm')}</>;
      }
    },
    {
      dataField: 'description',
      text: 'DESCRIPTION',
      headerStyle: { width: '50%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.user_role.description}</>;
      }
    }
  ];

  const handleAllClick = () => {
    setCurrentPage(1);
    loadData(id, 1, 10, 'id:desc');
  };

  const tabItems = [
    {
      label: 'All',
      value: 'all',
      count: total,
      onClick: handleAllClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  // const handleSearchInputChange = e => {};

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(id, page, sizePerPage, sortString);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'ip':
        setColumnName('ip');
        setSortString(`ip:${sortColumn}`);
        loadData(id, 1, 10, `ip:${sortColumn}`);
        break;
      case 'log_time':
        setColumnName('log_time');
        setSortString(`log_time:${sortColumn}`);
        loadData(id, 1, 10, `log_time:${sortColumn}`);
        break;
      case 'description':
        setColumnName('description');
        setSortString(`user_role.description:${sortColumn}`);
        loadData(id, 1, 10, `user_role.description:${sortColumn}`);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-2">{userName && `${userName}'s Logs`}</h2>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              {/* <SearchBox
                placeholder="Search orders"
                onChange={handleSearchInputChange}
              /> */}
              <div className="scrollbar overflow-hidden-y"></div>
              <div className="ms-xxl-auto"></div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default UserLogs;
