/* eslint-disable react/prop-types */
import InventoryTotalOrdersChart from 'components/charts/e-charts/InventoryTotalOrdersChart';
import { Card } from 'react-bootstrap';

interface Order {
  date: string;
  total: number;
}

interface InventoryTotalOrdersChartProps {
  orders: Order[];
}

const InventoryTotalOrdersCard = ({
  orders
}: InventoryTotalOrdersChartProps) => {
  const totalOrders =
    orders && orders.reduce((acc, item) => acc + item.total, 0);

  // const totalDays = orders && orders.length;

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">Total orders</h5>
            <h6 className="text-700">{`Last 7 days`}</h6>
          </div>
          <h4>{totalOrders}</h4>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <InventoryTotalOrdersChart orders={orders} />
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">Completed</h6>
            <h6 className="text-900 fw-semi-bold mb-0">100%</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InventoryTotalOrdersCard;
