import classNames from 'classnames';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface PageBreadcrumbItem {
  label: string;
  url?: string;
  active?: boolean;
}

interface PageBreadcrumbProps {
  items: PageBreadcrumbItem[];
  className?: string;
}

const PageBreadcrumb = ({ items, className }: PageBreadcrumbProps) => {
  return (
    <Breadcrumb className={classNames(className, 'mb-2')}>
      {items.map(item => (
        <Breadcrumb.Item
          linkProps={{ to: item.url }}
          linkAs={Link}
          active={item.active}
          key={item.label}
        >
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
