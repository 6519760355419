import InventoryTotalInventoryChart from 'components/charts/e-charts/InventoryTotalInventoryChart';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

const bgColor = [
  'info-300',
  'warning-300',
  'danger-300',
  'success-300',
  'primary',
  'info-300',
  'warning-300',
  'danger-300',
  'success-300',
  'primary'
];

interface InventoryStatus {
  name: string;
  type: string;
  id: number;
}

interface InventoryItems {
  status: InventoryStatus;
  total: number;
}

interface LastInventoryLog {
  added_time: string;
}

interface InventoryTotalInventoryCardProps {
  inventory: InventoryItems[];
  last_inventory_log: LastInventoryLog;
}

const InventoryTotalInventoryCard = ({
  inventory,
  last_inventory_log
}: InventoryTotalInventoryCardProps) => {
  const filteredInventory =
    inventory && inventory.filter(item => item.status !== null);

  const filteredTotalCount =
    inventory && inventory.filter(item => item.status === null);

  const inventoryStats =
    inventory &&
    filteredInventory.map((item, index) => ({
      name: item.status ? item.status.name : '',
      value: item.total,
      bg: bgColor[index] || 'primary'
    }));

  return (
    <Row className="g-3 mb-3">
      <Col xs={12} md={6}>
        <h3 className="text-1100 text-nowrap">Latest Inventory</h3>
        <p className="text-700 mb-md-7">
          {`Last inventory updated on ${moment(
            last_inventory_log && last_inventory_log.added_time
          ).format('MMM Do YYYY, HH:mm')}`}
        </p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">Status type </p>
          <p className="mb-0 fs-9">
            Total count{' '}
            <span className="fw-bold">
              {filteredTotalCount && filteredTotalCount[0].total}
            </span>
          </p>
        </div>
        <hr className="bg-200 mb-2 mt-2" />

        {inventoryStats &&
          inventoryStats.map(status => (
            <div className="d-flex align-items-center mb-1" key={status.name}>
              <span
                className={`d-inline-block bg-${status.bg} bullet-item me-2`}
              />
              <p className="mb-0 fw-semi-bold text-900 lh-sm flex-1">
                {status.name}
              </p>
              <h5 className="mb-0 text-900">{status.value}</h5>
            </div>
          ))}
      </Col>
      <Col xs={12} md={6}>
        <div className="position-relative mb-sm-4 mb-xl-0">
          <InventoryTotalInventoryChart
            inventory={inventoryStats && inventoryStats}
          />
        </div>
      </Col>
    </Row>
  );
};

export default InventoryTotalInventoryCard;
