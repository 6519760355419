import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'bootstrap/dist/css/bootstrap.min.css';

DownloadButton.propTypes = {
  data: PropTypes.any.isRequired
};

function DownloadButton({ data }) {
  const handleDownlandXlsx = () => {
    downloadXlsx();
  };
  const handleDownlandCsv = () => {
    downloadCsv();
  };

  const handleDownlandPdf = () => {
    downloadPdf();
  };

  const downloadXlsx = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const colWidths = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 }
    ];
    worksheet['!cols'] = colWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventory');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `inventory-${moment(new Date()).format('MM-DD-YYYY')}`;
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  const downloadCsv = () => {
    const csvFilename = `inventory-${moment(new Date()).format(
      'MM-DD-YYYY'
    )}.csv`;

    let stringArray = data.map(obj => [
      obj.make,
      obj.model,
      obj.serial_number,
      obj.calibre,
      obj.location,
      obj.user,
      obj.date,
      obj.status
    ]);

    const headerArray = [
      'make',
      'model',
      'serial_number',
      'calibre',
      'location',
      'user',
      'date',
      'status'
    ];

    stringArray = [headerArray, ...stringArray];

    const csvData = stringArray.map(row => String(row)).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', csvFilename);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadPdf = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const pdf = new jsPDF(orientation, unit, size);
    const title = 'SOLD ITEMS';
    pdf.text(title, 40, 50);

    pdf.setFontSize(10);

    const headers = [
      [
        'Make',
        'Model',
        'Serial_number',
        'Calibre',
        'Location',
        'User',
        'Date',
        'Status'
      ]
    ];
    const rows = data.map(obj => [
      obj.make,
      obj.model,
      obj.serial_number,
      obj.calibre,
      obj.location,
      obj.user,
      obj.date,
      obj.status
    ]);

    const headerStyles = {
      fillColor: '#ffffff',
      textColor: '#000000',
      fontStyle: 'bold'
    };

    const bodyStyles = {
      fillColor: '#ffffff'
    };

    pdf.autoTable({
      head: headers,
      body: rows,
      headStyles: headerStyles,
      alternateRowStyles: bodyStyles,
      startY: 70,
      willDrawCell: data => {
        // Draw horizontal lines after each row
        pdf.setDrawColor(0, 0, 0);
        pdf.setLineWidth(3);
        pdf.line(
          data.cell.x,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y
        );
      }
    });

    // Save the PDF as a file
    pdf.save(`inventory-${moment(new Date()).format('MM-DD-YYYY')}.pdf`);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="phoenix-secondary"
          size="l"
          className="bg-white hover-bg-100 dropdown-caret-none"
        >
          <FontAwesomeIcon icon={faDownload} className="10"></FontAwesomeIcon>
        </Dropdown.Toggle>

        <Dropdown.Menu align="start">
          <Dropdown.Item href="#" onClick={e => handleDownlandXlsx(e)}>
            XLSX
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={e => handleDownlandCsv(e)}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={e => handleDownlandPdf(e)}>
            PDF
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default DownloadButton;
