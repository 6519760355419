import { useEffect, useState } from 'react';
// import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import FeatherIcon from 'feather-icons-react';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getInventoryLogDetails } from 'api/api';
import DownloadButton from 'helpers/download-utils';

const breadcrumbItems = [
  {
    label: 'Inventory Logs',
    url: '/inventory/inventory-logs'
  },
  {
    label: 'Details',
    active: true
  }
];

const InventoryDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState(null);
  const [missingTotal, setMissingTotal] = useState(null);
  const [foundTotal, setFoundTotal] = useState(null);
  const [missingInActive, setMissingInActive] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');
  const [sortColumn, setSortColumn] = useState('asc');
  const [status, setStatus] = useState('all');
  //const [allCount, setAllCount] = useState(null);
  const [columnName, setColumnName] = useState('id');
  const [downLoadData, setDownLoadData] = useState([]);
  const [sortString, setSortString] = useState('item_stock.item.id:desc');
  const { id } = useParams();

  useEffect(() => {
    loadData(id, currentPage, pageSize, 'all', 'item_stock.item.id');
  }, [id]);

  useEffect(() => {
    loadDownloadData(id, 'all');
  }, [id]);

  const loadData = (id, pageNr, pageSize, status, sortBy) => {
    setError([]);
    setLoading(true);

    getInventoryLogDetails(id, pageNr, pageSize, status, sortBy)
      .then(response => {
        console.log(response);
        if (response.data.data.data.logs.length > 0) {
          console.log(response.data.data.data.logs);
          const flattenedData = response.data.data.data.logs.map(item => ({
            name: item.item.name,
            item_id: item.item.id,
            sku: item.item.sku,
            description: item.item.description,
            serial_number: item.item_stock.serial_number,
            category: item.item.category.name,
            parent_category: item.item.category.parent_category.name,
            container: item.item.container.name,
            epc: item.item_stock && item.item_stock.epc.slice(-8),
            location: item.item.container.location.name,
            status: item.item_stock.status.name,
            image: item.item.image
          }));

          setData(flattenedData);
        } else {
          setData([]);
        }
        setTotal(response.data.data.total);

        if (
          response.data.data.data.inventory !== null &&
          response.data.data.data.inventory !== undefined
        ) {
          const fTotal = response.data.data.data.inventory.found_total;
          const mTotal = response.data.data.data.inventory.missing_total;
          const mATotal = response.data.data.data.inventory.missing_in_active;

          setFoundTotal(fTotal);
          setMissingTotal(mTotal);
          setMissingInActive(mATotal);
          setDate(
            moment(response.data.data.data.inventory.added_time).format(
              'MMMM DD YYYY HH:MM'
            )
          );
          // setAllCount(fTotal + mTotal + mATotal);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadDownloadData = (id, status) => {
    setError([]);
    setDownLoadData([]);
    getInventoryLogDetails(id, 1, 0, status, 'item_stock.item.id:desc')
      .then(response => {
        if (response.data.data.data.logs.length > 0) {
          const flattenedData = response.data.data.data.logs.map(item => ({
            serial_number: item.item_stock.serial_number,
            make: item.item.name,
            model: item.item.description,
            category: item.item.category.name,
            calibre: item.item.sku,
            container: item.item.container.name,
            epc: item.item_stock && item.item_stock.epc.slice(-8),
            status: item.item_stock.status.name
          }));

          setDownLoadData(flattenedData);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'image',
      formatter: (cellContent, row) => (
        <Link
          to={`/items/item-details/${row.item_id}`}
          className="rounded-2 border d-inline-block"
        >
          <img src={row.image} alt="" width={53} />
        </Link>
      ),
      headerStyle: () => ({ width: '80px' })
    },
    {
      dataField: 'serial_number',
      text: 'SERIAL NUMBER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'serial_number'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.serial_number}</>;
      }
    },
    {
      dataField: 'name',
      text: 'MAKE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return (
          <>
            <Link
              to={`/items/item-details/${row.item_id}`}
              className="fw-semi-bold line-clamp-3"
            >
              {row.name}
            </Link>
          </>
        );
      }
    },
    {
      dataField: 'description',
      text: 'MODEL',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.description}</>;
      }
    },
    {
      dataField: 'category',
      text: 'CATEGORY',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'category'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.category}</>;
      }
    },

    {
      dataField: 'sku',
      text: 'CALIBRE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'sku' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.sku}</>;
      }
    },

    {
      dataField: 'epc',
      text: 'EPC',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'epc' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'container',
      text: 'CONTAINER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'container'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.container}</>;
      }
    },
    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (
          row.status.toLowerCase() === 'sold' ||
          row.status.toLowerCase() === 'in stock'
        ) {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    }
  ];

  const handleAllClick = () => {
    setStatus('all');
    setCurrentPage(1);
    loadData(id, 1, pageSize, 'all', 'item_stock.item.id:desc');
    loadDownloadData(id, 'all');
  };
  const handleTotalFoundClick = () => {
    setStatus('found_in_missing');
    setCurrentPage(1);
    loadData(id, 1, pageSize, 'found_in_missing', 'item_stock.item.id:desc');
    loadDownloadData(id, 'found_in_missing');
  };
  const handleMissingClick = () => {
    setStatus('missing');
    setCurrentPage(1);
    loadData(id, 1, pageSize, 'missing', 'item_stock.item.id:desc');
    loadDownloadData(id, 'missing');
  };
  const handleMissingInActiveClick = () => {
    setStatus('missing_in_active');
    setCurrentPage(1);
    loadData(id, 1, pageSize, 'missing_in_active', 'item_stock.item.id:desc');
    loadDownloadData(id, 'missing_in_active');
  };

  const tabItems = [
    {
      label: 'All',
      value: 'all',
      count: total, //allCount,
      onClick: handleAllClick
    },
    {
      label: 'Found',
      value: 'pending',
      count: foundTotal,
      onClick: handleTotalFoundClick
    },
    {
      label: 'Missing Total',
      value: 'completed',
      count: missingTotal,
      onClick: handleMissingClick
    },
    {
      label: 'Missing in Last Inventory',
      value: 'refunded',
      count: missingInActive,
      onClick: handleMissingInActiveClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  // const handleSearchInputChange = e => {};

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    console.log(sortString);
    loadData(id, page, sizePerPage, status, sortString);
  };
  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`item_stock.item.name:${sortColumn}`);
        loadData(id, 1, 10, status, `item_stock.item.name:${sortColumn}`);
        break;
      case 'sku':
        setColumnName('sku');
        setSortString(`item_stock.item.sku:${sortColumn}`);
        loadData(id, 1, 10, status, `item_stock.item.sku:${sortColumn}`);
        break;
      case 'description':
        setColumnName('description');
        setSortString(`item_stock.item.description:${sortColumn}`);
        loadData(
          id,
          1,
          10,
          status,
          `item_stock.item.description:${sortColumn}`
        );
        break;
      case 'serial_number':
        setColumnName('serial_number');
        setSortString(`item_stock.serial_number:${sortColumn}`);
        loadData(id, 1, 10, status, `item_stock.serial_number:${sortColumn}`);
        break;
      case 'epc':
        setColumnName('epc');
        setSortString(`item_stock.epc:${sortColumn}`);
        loadData(id, 1, 10, status, `item_stock.epc:${sortColumn}`);
        break;
      case 'parent_category':
        setColumnName('parent_category');
        setSortString(
          `item_stock.item.item_category.item_category.name:${sortColumn}`
        );
        loadData(
          id,
          1,
          10,
          status,
          `item_stock.item.item_category.item_category.name:${sortColumn}`
        );
        break;
      case 'category':
        setColumnName('category');
        setSortString(`item_stock.item.item_category.name:${sortColumn}`);
        loadData(
          id,
          1,
          10,
          status,
          `item_stock.item.item_category.name:${sortColumn}`
        );
        break;
      case 'container':
        setColumnName('container');
        setSortString(`item_stock.item.container.name:${sortColumn}`);
        loadData(
          id,
          1,
          10,
          status,
          `item_stock.item.container.name:${sortColumn}`
        );
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(id, 1, 10, status, `status.name:${sortColumn}`);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div>
          <h2 className="mb-2">Inventory Details</h2>
          <h5 className="text-700 fw-semi-bold">
            {`The inventory was conducted on ${date}`}
          </h5>
        </div>

        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              {/* <SearchBox
                placeholder="Search orders"
                onChange={handleSearchInputChange}
              /> */}
              <div className="scrollbar overflow-hidden-y"></div>
              <div className="ms-xxl-auto">
                <DownloadButton
                  data={downLoadData}
                  info={`The inventory was conducted on ${date}`}
                  allCount={total} //{allCount}
                  missingCount={missingTotal}
                  foundCount={foundTotal}
                  missingInStockCount={missingInActive}
                />
              </div>
            </div>
          </div>

          <div>
            {error &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default InventoryDetails;
