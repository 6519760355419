import InventoryLastInventoryLogChart from 'components/charts/e-charts/InventoryLastInventoryLogChart';
import moment from 'moment';
import { Card } from 'react-bootstrap';

interface LastInventoryLog {
  added_time: string;
  missing_in_active: number;
  found_total: number;
  missing_total: number;
}

interface InventoryLastInventoryCardProps {
  lastInventoryLog: LastInventoryLog;
}

const InventoryLastInventoryCard = ({
  lastInventoryLog
}: InventoryLastInventoryCardProps) => {
  return (
    <Card className="h-100">
      <Card.Body className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Last inventory log</h5>
            <h6 className="text-700">{`On ${
              lastInventoryLog &&
              moment(lastInventoryLog.added_time).format('MMMM Do YYYY, HH:mm')
            }`}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 flex-1">
          <InventoryLastInventoryLogChart lastInventoryLog={lastInventoryLog} />
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">Found total</h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {lastInventoryLog && lastInventoryLog.found_total}
            </h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-200 me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">Missing total</h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {lastInventoryLog && lastInventoryLog.missing_total}
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-info-500 me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
              Missing in active
            </h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {lastInventoryLog && lastInventoryLog.missing_in_active}
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InventoryLastInventoryCard;
