import React, { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchCategory, getCategory } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Category',
    url: '/items/category'
  },
  {
    label: 'Edit',
    active: true
  }
];

function EditCategory() {
  const navigate = useNavigate();
  const Form = withTheme(Bootstrap4Theme);
  const [formData, setFormData] = useState();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = id => {
    setError([]);
    setLoading(true);

    getCategory(id)
      .then(response => {
        setFormData(response.data.data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = form_data => {
    setError([]);
    setFormData(form_data.formData);
    setLoading(true);

    patchCategory(form_data.formData.id, form_data.formData)
      .then(response => {
        if (response.status === 200) navigate('/items/category');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const schema = {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        title: 'Category Name',
        type: 'string'
      },
      description: {
        title: 'Description',
        type: 'string'
      }
    }
  };

  const uiSchema = {
    'ui:field': 'layout',
    name: {
      'ui:autofocus': true
    },
    description: {
      'ui:widget': 'textarea'
    },
    'ui:layout': [{ name: { md: 8 } }, { description: { md: 8 } }]
  };

  const fields = {
    layout: LayoutField
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Edit a Category</h2>
            <h5 className="text-700 fw-semi-bold">Edit a category</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              <Form
                schema={schema}
                uiSchema={uiSchema}
                fields={fields}
                onSubmit={handleSubmit}
                formData={formData}
                showErrorList={false}
              >
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ marginTop: '10px' }}
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    onClick={() => navigate('/items/category')}
                  >
                    Discard
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditCategory;
