import { useState, useEffect, useRef } from 'react';
import Button from 'components/base/Button';
import { Col, Row, Alert, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { getUserRoles, getUserGroup, deleteUserGroup } from 'api/api';

const breadcrumbItems = [
  {
    label: 'User group',
    url: '/user-group/user-group'
  },
  {
    label: 'Delete',
    active: true
  }
];

function DeleteUserGroup() {
  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [name, setName] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadUserRoles();
  }, []);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadUserRoles = () => {
    setError([]);
    setLoading(true);

    getUserRoles()
      .then(response => {
        setUserRoles(response.data.data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadData = id => {
    setError([]);
    setLoading(true);

    getUserGroup(id)
      .then(response => {
        const grantedIds = collectGrantedIds(response.data.data.roles);
        setName(response.data.data.name);
        setSelectedCheckboxes(grantedIds);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);

    deleteUserGroup(id)
      .then(response => {
        if (response.status === 200) navigate('/user-group/user-group');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line react/prop-types
  const CheckboxList = ({ data }) => {
    const renderCheckboxes = items => {
      return items.map(item => {
        const isChecked = selectedCheckboxes.includes(item.id);
        if (item.is_activity) {
          return (
            <>
              <Form.Group key={item.id} as={Col} xs={12} sm={3}>
                <Form.Check
                  type="checkbox"
                  id={`checkbox_${item.id}`}
                  label={item.role}
                  checked={isChecked}
                  onChange={() => handleCheckboxChange(item.id)}
                  disabled={true}
                />
              </Form.Group>
            </>
          );
        } else {
          // Render subheading and checkboxes in a new row for non-activities
          return (
            <>
              <Row key={item.id}>
                <div>
                  <Col>{<h6>{item.role}</h6>}</Col>
                </div>
              </Row>
              <div style={{ marginLeft: '20px' }}>
                <Row key={item.id}>{renderCheckboxes(item.roles)}</Row>
              </div>
            </>
          );
        }
      });
    };

    return <>{renderCheckboxes(data)}</>;
  };

  const collectGrantedIds = items => {
    let allIds = [];
    items.forEach(item => {
      if (item.granted) {
        allIds.push(item.id);
      }
      if (item.roles && item.roles.length > 0) {
        allIds = [...allIds, ...collectGrantedIds(item.roles)];
      }
    });
    return allIds;
  };

  const handleCheckboxChange = itemId => {
    const updatedCheckboxes = selectedCheckboxes.includes(itemId)
      ? selectedCheckboxes.filter(id => id !== itemId)
      : [...selectedCheckboxes, itemId];
    setSelectedCheckboxes(updatedCheckboxes);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Edit User Group</h2>
            <h5 className="text-700 fw-semi-bold">Edit a user group</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={12}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              {/* <CheckboxList data={userRoles} /> */}
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} xl={4}>
                    <Form.Group>
                      <label>Name*</label>
                      <Form.Control
                        className="mb-5"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        ref={nameInputRef}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <CheckboxList data={userRoles} />
                </Row>

                <Row style={{ marginTop: '5px' }}>
                  <Col xs={12} xl={4}>
                    <Button variant="danger" type="submit">
                      Delete
                    </Button>{' '}
                    <Button
                      variant="phoenix-secondary"
                      type="button"
                      onClick={() => navigate('/user-group/user-group')}
                    >
                      Discard
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DeleteUserGroup;
