import { useState, useRef, useEffect } from 'react';
import { UilAsterisk } from '@iconscout/react-unicons';
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import { Card, Row, Col, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import { searchStockByEpc, createOrder } from 'api/api';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import Avatar from 'components/base/Avatar';

const breadcrumbItems = [
  {
    label: 'Orders',
    url: '/orders/orders'
  },
  {
    label: 'Add',
    active: true
  }
];

function AddOrder() {
  const epcInputRef = useRef(null);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [name, setName] = useState('');
  const [epc, setEpc] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [sku, setSKU] = useState('');
  const [container, setContainer] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [validateError, setValidateError] = useState([]);
  const [itemStockId, setItemStockId] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (epcInputRef.current) {
      epcInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [searchText]);

  const loadData = () => {
    setValidateError([]);
    setVisibility(false);
    setItemStockId(null);

    if (searchText.length > 5) {
      searchStockByEpc(searchText)
        .then(response => {
          setVisibility(true);
          const data = response.data.data;
          console.log(data);
          setItemStockId(data.stock.id);
          setName(data.name);
          setDescription(data.description);
          setEpc(data.stock.epc);
          setSerialNumber(data.stock.serial_number);
          setSKU(data.sku);
          setContainer(data.container.name);
          setLocation(data.container.location.name);
          setCategory(data.category.name);
          setImage(data.image);
          setValidateError([]);
        })
        .catch(err => {
          try {
            if (err.response.status === 401) {
              navigate('/sign-in');
            }
            setValidateError(err.response.data.errors);
          } catch (ex) {
            setValidateError(['Please try again later...']);
          }
        });
    }
  };

  const handleOnChangeSerialNumber = value => {
    setValidateError([]);
    setSearchText(value);
  };

  const handleSubmitOrder = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);
    createOrder({ item_stock_id: itemStockId })
      .then(response => {
        if (response.status === 200) navigate('/orders/orders');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Add Order</h2>
            <h5 className="text-700 fw-semi-bold">Add an order</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>
        <Row className="g-0 g-md-4 g-xl-6">
          <Col md={5} xl={4}>
            <div>
              {loading && <LoadingOverlay />}

              <Row style={{ marginBottom: '-10px' }}>
                <Col xs={12} xl={12}>
                  <h5 className="mb-3">EPC Number</h5>
                  <Form.Control
                    className="mb-5"
                    type="text"
                    value={searchText}
                    onChange={e => handleOnChangeSerialNumber(e.target.value)}
                    ref={epcInputRef}
                    required
                  />
                  <div style={{ marginTop: '-30px', color: 'red' }}>
                    {validateError}
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  marginTop: '25px',
                  visibility: visibility === true ? 'visible' : 'hidden'
                }}
              >
                <Col xs={12} xl={12}>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={e => handleSubmitOrder(e)}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={5} xl={4} className="d-none d-md-block">
            <Card
              className="mb-3"
              style={{ visibility: visibility === true ? 'visible' : 'hidden' }}
            >
              <Card.Body>
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                  }}
                >
                  <Avatar size={'5xl'} status={''} src={image} />
                </div>

                <div className="d-flex align-items-center mb-5">
                  <h3>About Item</h3>
                </div>

                <LeadInfoItem className="mb-4" label="Make" icon={UilAsterisk}>
                  {name}
                </LeadInfoItem>

                <LeadInfoItem className="mb-4" label="EPC" icon={UilAsterisk}>
                  {epc}
                </LeadInfoItem>

                <LeadInfoItem className="mb-4" label="Model" icon={UilAsterisk}>
                  {description}
                </LeadInfoItem>

                <LeadInfoItem
                  className="mb-4"
                  label="Serial Number"
                  icon={UilAsterisk}
                >
                  {serialNumber}
                </LeadInfoItem>

                <LeadInfoItem
                  className="mb-4"
                  label="Calibre"
                  icon={UilAsterisk}
                >
                  {sku}
                </LeadInfoItem>
              </Card.Body>
            </Card>
          </Col>

          <Col md={5} xl={4} className="d-none d-md-block">
            <Card
              className="mb-3"
              style={{ visibility: visibility === true ? 'visible' : 'hidden' }}
            >
              <Card.Body>
                <div className="d-flex align-items-center mb-5">
                  <h3>Container & Category</h3>
                </div>
                <LeadInfoItem
                  className="mb-4"
                  label="Container"
                  icon={UilAsterisk}
                >
                  {`${location} > ${container}`}
                </LeadInfoItem>

                {/* <LeadInfoItem
                className="mb-4"
                label="Location"
                icon={UilAsterisk}
              >
                {location}
              </LeadInfoItem> */}

                <LeadInfoItem
                  className="mb-4"
                  label="Category"
                  icon={UilAsterisk}
                >
                  {category}
                </LeadInfoItem>

                {/* <LeadInfoItem
                className="mb-4"
                label="Parent Category"
                icon={UilAsterisk}
              >
                {parentCategory}
              </LeadInfoItem> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddOrder;
