import { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import { createItemStocks, getItem } from 'api/api';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import xlsxFile from '../../../assets/download/stock_format.xlsx';

function AddStockItemBulk() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemName, setItemName] = useState(null);
  const [files, setFiles] = useState([]);
  const [xlsxData, setXLSXData] = useState(null);
  const { id } = useParams();
  const customState = state && state.customState;

  let breadcrumbItems = [];
  if (customState === 'stockItem') {
    breadcrumbItems = [
      {
        label: 'Item',
        url: `/items/items`
      },
      {
        label: 'Item Stocks',
        url: `/items/stock-item/${id}`
      },
      {
        label: 'Add Stock',
        active: true
      }
    ];
  } else if (customState === 'itemDetails') {
    breadcrumbItems = [
      {
        label: 'Item Details',
        url: `/items/item-details/${id}`
      },
      {
        label: 'Add Stock',
        active: true
      }
    ];
  }
  useEffect(() => {
    loadItem();
  }, [id]);

  const loadItem = () => {
    setError([]);

    setLoading(true);

    getItem(id)
      .then(async response => {
        const data = response.data.data;
        setItemName(data.name);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = () => {
    setError([]);
    setLoading(true);

    if (xlsxData) {
      const stocks = { stocks: xlsxData };
      createItemStocks(id, stocks)
        .then(response => {
          if (response.status === 200) {
            if (customState === 'stockItem')
              navigate('/items/stock-item/' + id);
            if (customState === 'itemDetails')
              navigate('/items/item-details/' + id);
          }
        })
        .catch(err => {
          try {
            setError(err.response.data.errors);
          } catch (error) {
            setError(['Please try again later...']);
          }
        });
    }

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const validateColumns = header => {
    return header[0] === 'epc' && header[1] === 'serial_number';
  };

  const handleAdd = newFiles => {
    newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
    setFiles([...files, ...newFiles]);

    const file = newFiles[0];
    const reader = new FileReader();

    reader.onload = event => {
      const data = event.target.result;
      const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const xlsxData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const header = xlsxData[0];
      if (validateColumns(header)) {
        const parsedData = xlsxData.slice(1).map(row => ({
          epc: String(row[0]),
          serial_number: String(row[1])
        }));
        setXLSXData(parsedData);
      } else {
        setError([
          'Please upload a file with "epc" and "serial_number" columns.'
        ]);
        setXLSXData(null);
      }
    };

    reader.readAsArrayBuffer(file.file);
  };

  const handleDelete = deleted => {
    setXLSXData(null);
    setFiles(files.filter(f => f !== deleted));
  };

  const handleDownloadXlsx = () => {
    const link = document.createElement('a');
    link.href = xlsxFile;
    link.setAttribute('download', 'stock_format.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGoBack = () => {
    if (customState === 'stockItem') navigate('/items/stock-item/' + id);
    if (customState === 'itemDetails') navigate('/items/item-details/' + id);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">{`Add a Stock for '${itemName}'`}</h2>
            <h5 className="text-700 fw-semi-bold">Add new stocks </h5>
          </div>
        </div>
        <div className="ms-xxl-auto" style={{ float: 'right' }}>
          <Button variant="primary" onClick={() => handleDownloadXlsx()}>
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Download XLSX Format
          </Button>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              <DropzoneAreaBase
                fileObjects={files}
                onAdd={handleAdd}
                onDelete={handleDelete}
                acceptedFiles={['.xlsx']}
                maxFileSize={5000000}
                filesLimit={1}
                dropzoneText="Drag and drop an XLSX file here or click"
              />

              <div
                className="d-flex flex-wrap gap-2"
                style={{ marginTop: '10px' }}
              >
                <Button
                  variant="primary"
                  type="button"
                  onClick={e => handleSubmit(e)}
                >
                  Submit
                </Button>
                <Button
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => handleGoBack()}
                >
                  Discard
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddStockItemBulk;
