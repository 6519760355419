import InventoryTopOrderItemsChart from 'components/charts/e-charts/InventoryTopOrderItemsChart';
import { Card } from 'react-bootstrap';

interface TopOrderItems {
  name: string;
  percentage: string;
  total: number;
}

interface InventoryTopOrderItemsCardProps {
  topOrderItems: TopOrderItems[];
}

const InventoryTopOrderItemsCard = ({
  topOrderItems
}: InventoryTopOrderItemsCardProps) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Top order items</h5>
            <h6 className="text-700"></h6>
          </div>
        </div>
        <div className="pb-4 pt-3">
          <InventoryTopOrderItemsChart topOrderItems={topOrderItems} />
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
              {topOrderItems &&
                topOrderItems.length >= 1 &&
                topOrderItems[0].name}
            </h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {topOrderItems &&
                topOrderItems.length >= 1 &&
                `${parseInt(topOrderItems[0].percentage)}%`}
            </h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-200 me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
              {topOrderItems &&
                topOrderItems.length >= 2 &&
                topOrderItems[1].name}
            </h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {topOrderItems &&
                topOrderItems.length >= 2 &&
                `${parseInt(topOrderItems[1].percentage)}%`}
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-info-500 me-2"></div>
            <h6 className="text-900 fw-semi-bold flex-1 mb-0">
              {topOrderItems &&
                topOrderItems.length >= 3 &&
                topOrderItems[2].name}
            </h6>
            <h6 className="text-900 fw-semi-bold mb-0">
              {topOrderItems &&
                topOrderItems.length >= 3 &&
                `${parseInt(topOrderItems[2].percentage)}%`}
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InventoryTopOrderItemsCard;
