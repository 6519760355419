import React, { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { deleteCategory, getCategory } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Category',
    url: '/items/category'
  },
  {
    label: 'Delete',
    active: true
  }
];

function DeleteCategory() {
  const navigate = useNavigate();
  const Form = withTheme(Bootstrap4Theme);
  const [formData, setFormData] = useState();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = id => {
    setError([]);
    setLoading(true);

    getCategory(id)
      .then(response => {
        setFormData({
          id: id,
          name: response.data.data.name,
          description:
            response.data.data.description === null
              ? ''
              : response.data.data.description
        });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = () => {
    setError([]);
    setLoading(true);

    deleteCategory(id)
      .then(response => {
        console.log(response);
        if (response.status === 204) navigate('/items/category');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const schema = {
    type: 'object',
    properties: {
      name: {
        title: 'Category Name',
        type: 'string',
        readOnly: true
      },
      description: {
        title: 'Description',
        type: 'string',
        readOnly: true
      }
    }
  };

  const uiSchema = {
    'ui:field': 'layout',
    description: {
      'ui:widget': 'textarea',
      'ui:disabled': true
    },
    'ui:layout': [{ name: { md: 8 } }, { description: { md: 8 } }],
    name: {
      'ui:disabled': true
    }
  };

  const fields = {
    layout: LayoutField
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Delete Category</h2>
            <h5 className="text-700 fw-semi-bold">Delete a category</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              <Form
                schema={schema}
                uiSchema={uiSchema}
                fields={fields}
                onSubmit={handleSubmit}
                formData={formData}
                showErrorList={false}
              >
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ marginTop: '10px' }}
                >
                  <Button variant="danger" type="submit">
                    Delete
                  </Button>
                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    onClick={() => navigate('/items/category')}
                  >
                    Discard
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DeleteCategory;
