import { useState, useRef, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchUserPassword, getUser } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Users',
    url: '/users/users'
  },
  {
    label: 'Change Password',
    active: true
  }
];

const UpdatePasswordUser = () => {
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { id } = useParams();

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = id => {
    setError([]);

    getUser(id)
      .then(response => {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPasswordsMatch(true);

    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setError([]);
    setLoading(true);

    const formData = {
      password: password
    };

    patchUserPassword(id, formData)
      .then(response => {
        if (response.status === 200) {
          navigate('/users/users');
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handlePasswordChange = e => {
    setPassword(e);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Change Password</h2>
            <h5 className="text-700 fw-semi-bold">{`Change ${firstName} ${lastName}'s  password`}</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>Password*</label>
                <InputGroup>
                  <Form.Control
                    className="mb-2"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => handlePasswordChange(e.target.value)}
                    required
                    ref={passwordInputRef}
                  />
                  <InputGroup.Text
                    onClick={togglePasswordVisibility}
                    style={{ height: '38px' }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Confirm Password*</label>
                <InputGroup>
                  <Form.Control
                    className="mb-2"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={e => handleConfirmPasswordChange(e.target.value)}
                    required
                  />
                  <InputGroup.Text
                    onClick={togglePasswordConfirmVisibility}
                    style={{ height: '38px' }}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>{' '}
                </InputGroup>
                {!passwordsMatch && (
                  <p className="mb-2" style={{ color: 'red' }}>
                    Passwords do not match.
                  </p>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <Button variant="primary" type="submit" className="mb-2">
                  Submit
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/users/users')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordUser;
